import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  MarkTenderPayoutAsQueued,
  MarkTenderPayoutAsQueuedVariables,
} from './__generated__/MarkTenderPayoutAsQueued';

const MARK_TENDER_PAYOUT_AS_QUEUED = gql`
  mutation MarkTenderPayoutAsQueued($id: ID!) {
    queueForEvereeTenderPayout(id: $id) {
      id
      status
    }
  }
`;

export const useMarkTenderPayoutAsQueued = (
  options?: MutationHookOptions<
    MarkTenderPayoutAsQueued,
    MarkTenderPayoutAsQueuedVariables
  >,
) =>
  useMutation<MarkTenderPayoutAsQueued, MarkTenderPayoutAsQueuedVariables>(
    MARK_TENDER_PAYOUT_AS_QUEUED,
    options,
  );

export default MARK_TENDER_PAYOUT_AS_QUEUED;
