import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import BookingsView from 'src/views/Bookings';
import BookingDetailView from 'src/views/BookingDetail';
import BulkBonusPayments from 'src/views/BulkBonusPayments';
import StaffingsView from 'src/views/Staffings';
import InvoiceCreate from 'src/views/InvoiceCreate';
import Invoices from 'src/views/Invoices';
import NotFoundView from 'src/views/NotFound';
import RegionDetail from 'src/views/RegionDetail';
import Regions from 'src/views/Regions';
import Rosters from 'src/views/Rosters';
import RosterDetail from 'src/views/RosterDetail';
import StaffingRegionsView from 'src/views/StaffingRegions';
import TenderDetailView from 'src/views/TenderDetail';
import TenderPayouts from 'src/views/TenderPayouts';
import TendersView from 'src/views/Tenders';
import PendingPayouts from 'src/views/PendingPayouts';
import PaidStaffings from 'src/views/PaidStaffings';
import Shell from 'src/components/Shell';
import ViewRoute from 'src/components/ViewRoute';
import ClientAssignments from '../ClientAssignments';
import TenderCertifications from '../TenderCertifications';
import PendingEvereePayouts from '../PendingEvereePayouts';

const Application: React.FC = () => {
  const flags = useFlags();

  return (
    <Shell>
      <Switch>
        <Redirect exact path="/" to="/staffing" />
        <ViewRoute
          title="Bookings"
          path="/bookings"
          component={BookingsView}
          exact
        />
        <ViewRoute
          title="Bookings"
          path="/bookings/:jobId"
          component={BookingDetailView}
          exact
        />
        <ViewRoute
          title="Staffings"
          path="/staffing"
          component={StaffingsView}
          exact
        />
        <ViewRoute
          title="Tender Payments"
          path="/tender-payments"
          component={TenderPayouts}
          exact
        />
        <ViewRoute
          title="Tenders Panel"
          path="/tenders"
          component={TendersView}
          exact
        />
        <ViewRoute
          title="Tenders Panel"
          path="/tender/:id"
          component={TenderDetailView}
          exact
        />
        <ViewRoute
          title="Paid Staffings"
          path="/paid-staffings"
          component={PaidStaffings}
          exact
        />
        <ViewRoute
          title="Pending Payments"
          path="/pending-payments"
          component={PendingPayouts}
          exact
        />
        <ViewRoute
          title="Pending Everee Payments"
          path="/pending-everee-payments"
          component={PendingEvereePayouts}
          exact
        />
        <ViewRoute
          title="Invoices"
          path="/invoice-create"
          component={InvoiceCreate}
          exact
        />
        <ViewRoute
          title="Invoices"
          exact
          path="/invoices"
          component={Invoices}
        />
        <ViewRoute
          title="Manage Regions"
          path="/region/:regionId"
          component={RegionDetail}
          exact
        />
        <ViewRoute
          title="Manage Regions"
          path="/regions"
          component={Regions}
          exact
        />
        {flags.staffingRegions && (
          <ViewRoute
            title="Staffing Regions"
            path="/staffing-regions"
            component={StaffingRegionsView}
            exact
          />
        )}
        <ViewRoute
          title="Created Rosters"
          path="/rosters"
          component={Rosters}
          exact
        />
        <ViewRoute
          title="Roster Detail"
          path="/roster/:id"
          component={RosterDetail}
          exact
        />
        <ViewRoute
          title="Bulk Bonus Payments"
          path="/bulk-bonus-payments"
          component={BulkBonusPayments}
          exact
        />
        {flags.showClientManagement && (
          <ViewRoute
            title="Client Assignments"
            path="/assignments"
            component={ClientAssignments}
            exact
          />
        )}
        {flags.showTenderCertifications && (
          <ViewRoute
            title="Tender Certifications"
            path="/tender-certifications"
            component={TenderCertifications}
            exact
          />
        )}
        <ViewRoute title="Not Found" component={NotFoundView} />
      </Switch>
    </Shell>
  );
};

export default Application;
