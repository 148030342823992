import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  BulkQueueForEvereeTenderPayout,
  BulkQueueForEvereeTenderPayoutVariables,
} from './__generated__/BulkQueueForEvereeTenderPayout';

const BULK_QUEUE_TENDER_PAYOUT_FOR_EVEREE = gql`
  mutation BulkQueueForEvereeTenderPayout($filters: BulkTenderPayoutFilters!) {
    bulkQueueForEvereeTenderPayout(filters: $filters) {
      id
      status
    }
  }
`;

export const useBulkQueueForEvereeTenderPayout = (
  options?: MutationHookOptions<
    BulkQueueForEvereeTenderPayout,
    BulkQueueForEvereeTenderPayoutVariables
  >,
) =>
  useMutation<
    BulkQueueForEvereeTenderPayout,
    BulkQueueForEvereeTenderPayoutVariables
  >(BULK_QUEUE_TENDER_PAYOUT_FOR_EVEREE, options);

export default BULK_QUEUE_TENDER_PAYOUT_FOR_EVEREE;
