import React, { useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ApolloError } from '@apollo/client';

import {
  OrderByDirectionEnum,
  TenderPaymentStatus,
} from 'src/__generated__/globalTypes';
import { useRequestTenderPayoutsCSVMutation } from 'src/graphql/mutations/RequestTenderPayoutsCSV';
import { useGetTenderPaymentsQuery } from 'src/graphql/queries/GetTenderPayouts';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import Download from 'src/components/Icons/Download';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PendingPayoutsTable from './PendingPayoutsTable';

const PendingPayouts: React.FC = () => {
  const [operationError, setOperationError] = useState<string | undefined>();
  const [isCSVRequestFinished, setIsCSVRequestFinished] = useState(false);
  const [orderByField, setOrderByField] = useState('createdAt');
  const [orderByDirection, setOrderByDirection] =
    useState<OrderByDirectionEnum>(OrderByDirectionEnum.DESC);
  const sort = (field: string) => {
    setOrderByDirection(
      orderByField === field
        ? orderByDirection === OrderByDirectionEnum.ASC
          ? OrderByDirectionEnum.DESC
          : OrderByDirectionEnum.ASC
        : OrderByDirectionEnum.ASC,
    );
    setOrderByField(field);
  };

  const flags = useFlags();

  const PENDING_PAYOUTS_FILTERS = {
    status: { contains: [TenderPaymentStatus.PENDING] },
    ...(flags.evereeApiIntegration
      ? {
          everee: {
            equals: false,
          },
        }
      : {}),
    paymentsProviderStatus: {
      empty: true,
    },
  };
  const {
    data: tenderPaymentsData,
    fetchMore,
    error,
    loading,
  } = useGetTenderPaymentsQuery({
    variables: {
      filters: PENDING_PAYOUTS_FILTERS,
      orderByField,
      orderByDirection,
    },
  });
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage:
      tenderPaymentsData?.tenderPayments.pageInfo.hasNextPage || false,
    onLoadMore: () =>
      fetchMore({
        variables: {
          filters: PENDING_PAYOUTS_FILTERS,
          after: tenderPaymentsData?.tenderPayments.pageInfo.endCursor,
          orderByField,
          orderByDirection,
        },
      }),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
  });

  const [requestTenderPayoutsCSV, { loading: requestingTenderPayoutsCSV }] =
    useRequestTenderPayoutsCSVMutation();

  const handleRequestTenderPayoutsCSVClick = async ({
    isEvereeExport,
  }: {
    isEvereeExport: boolean;
  }) => {
    if (requestingTenderPayoutsCSV) {
      return;
    }

    try {
      await requestTenderPayoutsCSV({
        variables: {
          filters: PENDING_PAYOUTS_FILTERS,
          options: { isEvereeExport },
        },
      });
      setIsCSVRequestFinished(true);
    } catch (e) {
      if (e instanceof ApolloError) {
        setOperationError(e.message);
      } else {
        throw e;
      }
    }
  };

  return (
    <>
      <div className="flex h-full flex-col overflow-y-scroll">
        <div className="sticky top-0 z-20 overflow-x-visible">
          <div className="inline-block min-w-full align-middle">
            <div className="border-support-line border sm:rounded-lg">
              <div className="bg-background-surface flex items-center justify-between py-5">
                <h3 className="text-ink-dark flex-1 pl-6 text-2xl font-light leading-7">
                  {tenderPaymentsData?.tenderPayments.pageInfo.totalCount}{' '}
                  Pending Payments - Canada and US Bonus
                </h3>
                <div
                  onClick={() =>
                    handleRequestTenderPayoutsCSVClick({
                      isEvereeExport: true,
                    })
                  }
                  className="text-preset-5 text-primary mr-4 flex cursor-pointer items-center px-4"
                >
                  <Download className="mr-2 inline-block h-4 w-4" />
                  Download Everee CSV
                </div>
                <div
                  onClick={() =>
                    handleRequestTenderPayoutsCSVClick({
                      isEvereeExport: false,
                    })
                  }
                  className="text-preset-5 text-primary mr-4 flex cursor-pointer items-center px-4"
                >
                  <Download className="mr-2 inline-block h-4 w-4" />
                  Download CSV
                </div>
              </div>
            </div>
          </div>
          <PendingPayoutsTable
            sort={sort}
            orderByDirection={orderByDirection}
            orderByField={orderByField}
            tenderPayments={tenderPaymentsData?.tenderPayments.edges}
            totalCount={tenderPaymentsData?.tenderPayments.pageInfo.totalCount}
          />
          {(loading ||
            tenderPaymentsData?.tenderPayments.pageInfo.hasNextPage) && (
            <div ref={sentryRef}>Loading more...</div>
          )}
        </div>
      </div>
      <ConfirmationDialog
        open={isCSVRequestFinished}
        title="CSV Requested"
        description="You will receive an email with the CSV export shortly"
        cancelText="Close"
        onClose={() => setIsCSVRequestFinished(false)}
      />
      <ConfirmationDialog
        open={Boolean(operationError)}
        title="Operation error"
        description={operationError || ''}
        cancelText="Close"
        onClose={() => setOperationError(undefined)}
      />
    </>
  );
};

export default PendingPayouts;
