import React, { useState } from 'react';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import ViewContainer from 'src/components/ViewContainer';
import Button from 'src/components/Button';
import GET_TENDER_PAYMENTS from 'src/graphql/queries/GetTenderPayouts';
import { useBulkMarkChangeTenderPayoutStatusAsCancelled } from 'src/graphql/mutations/BulkMarkTenderPayoutAsCancelled';
import {
  OrderByDirectionEnum,
  TenderPaymentStatus,
} from 'src/__generated__/globalTypes';
import { useBulkQueueForEvereeTenderPayout } from '../../graphql/mutations/BulkMarkTenderPayoutAsQueued';

type Props = {
  orderByField: string;
  orderByDirection: OrderByDirectionEnum;
  tenderPayoutIds: string[];
  onClearSelection: () => void;
  selectionCount: number;
  hasAllSelected: boolean;
};

const TenderEvereePayoutsSelectionToolbar: React.FC<Props> = ({
  orderByField,
  orderByDirection,
  tenderPayoutIds,
  onClearSelection,
  selectionCount,
  hasAllSelected,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const PENDING_PAYOUTS_FILTERS = {
    status: {
      contains: [TenderPaymentStatus.PENDING],
    },
    everee: {
      equals: true,
    },
  };

  const [mutation, { loading: sentLoading }] =
    useBulkQueueForEvereeTenderPayout({
      refetchQueries: [
        {
          query: GET_TENDER_PAYMENTS,
          variables: {
            filters: PENDING_PAYOUTS_FILTERS,
            orderByField,
            orderByDirection,
          },
        },
      ],
      onCompleted: () => {
        onClearSelection();
        setIsDialogOpen(false);
      },
    });
  const [cancelMutation, { loading: cancelLoading }] =
    useBulkMarkChangeTenderPayoutStatusAsCancelled({
      refetchQueries: [
        {
          query: GET_TENDER_PAYMENTS,
          variables: {
            filters: PENDING_PAYOUTS_FILTERS,
            orderByField,
            orderByDirection,
          },
        },
      ],
      onCompleted: () => {
        onClearSelection();
        setIsDialogOpen(false);
      },
    });
  const loading = sentLoading || cancelLoading;
  const mutationVariables = {
    filters: {
      tenderPayoutIds: {
        contains: hasAllSelected ? undefined : tenderPayoutIds,
        notContains: hasAllSelected ? tenderPayoutIds : undefined,
      },
    },
  };
  const handleOnSubmit = async () => {
    await mutation({
      variables: mutationVariables,
    });
  };
  const handleOnCancelSubmit = async () => {
    await cancelMutation({
      variables: mutationVariables,
    });
  };
  if (!selectionCount) {
    return null;
  }

  return (
    <div className="bg-background-surface border-support-line shadow-bottom-toolbar fixed bottom-0 w-full border-t">
      <ViewContainer className="py-2">
        <div className="flex">
          <div className="flex flex-1 items-center">
            <div className="text-ink-not-as-dark text-preset-6 ml-2 mr-6">
              {selectionCount} Selected
            </div>
            <Button onClick={onClearSelection} disabled={loading}>
              Unselect all
            </Button>
          </div>
          <div className="flex">
            <Button
              className="mr-4"
              primary
              onClick={() => setIsDialogOpen(true)}
              disabled={loading}
              data-cy="approve-all-button"
            >
              Queue all for Everee
            </Button>
            <Button
              className="bg-status-destructive text-ink-clear mr-4"
              primary
              onClick={() => setIsCancelDialogOpen(true)}
              disabled={loading}
              data-cy="approve-all-button"
            >
              Mark all as cancelled
            </Button>
          </div>
        </div>
      </ViewContainer>
      <ConfirmationDialog
        title="Queue all to be sent to Everee?"
        description="All selected payments be queued to be sent to everee."
        open={isDialogOpen}
        submitText="Mark all as sent"
        onSubmit={handleOnSubmit}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      <ConfirmationDialog
        title="Mark all payments as cancelled?"
        description="All selected payments will be mark as cancelled and there is no way to undo this action."
        open={isCancelDialogOpen}
        submitText="Mark all as cancelled"
        submitClassName="bg-status-destructive text-ink-clear"
        onSubmit={handleOnCancelSubmit}
        onClose={() => {
          setIsCancelDialogOpen(false);
        }}
      />
    </div>
  );
};

export default TenderEvereePayoutsSelectionToolbar;
