import React, { Fragment, useContext, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import NavigationLink from 'src/components/NavigationLink';
import NavigationSectionGroup from 'src/components/NavigationSectionGroup';
import HamburgerIcon from 'src/components/Icons/Hamburger';
import Loading from 'src/components/Loading';
import { Can } from 'src/contexts/AbilityContext';
import { AdminProfileContext } from 'src/contexts/AdminProfileContext';

const NavigationItems: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const flags = useFlags();

  return (
    <nav className="space-y-1 px-2">
      <NavigationSectionGroup>Bookings</NavigationSectionGroup>

      <div className="pl-2">
        <NavigationLink onClick={onClick} to="/staffing">
          Staffing
        </NavigationLink>
        <Can I="read" a="Booking">
          <NavigationLink onClick={onClick} to="/bookings">
            Bookings
          </NavigationLink>
        </Can>
        <Can I="read" a="Roster">
          <NavigationLink onClick={onClick} to="/rosters">
            Rosters
          </NavigationLink>
        </Can>
      </div>

      <Can I="read" a="Payment">
        <NavigationSectionGroup>Accounting</NavigationSectionGroup>
        <div className="pl-2">
          <NavigationLink onClick={onClick} to="/paid-staffings">
            Paid Staffings
          </NavigationLink>
          <NavigationLink onClick={onClick} to="/tender-payments">
            Tender Payments
          </NavigationLink>
          <NavigationLink onClick={onClick} to="/bulk-bonus-payments">
            Bulk Bonus Payments
          </NavigationLink>
          <NavigationLink onClick={onClick} to="/pending-payments">
            Pending Payments
          </NavigationLink>
          {flags.evereeApiIntegration && (
            <NavigationLink onClick={onClick} to="/pending-everee-payments">
              Pending Everee Payments
            </NavigationLink>
          )}
          <NavigationLink onClick={onClick} to="/invoice-create">
            Create Invoice
          </NavigationLink>
          <NavigationLink onClick={onClick} to="/invoices">
            Invoices Historical
          </NavigationLink>
        </div>
      </Can>

      <Can I="update" a="Tender">
        <NavigationSectionGroup>Tenders Management</NavigationSectionGroup>
        <div className="pl-2">
          <NavigationLink onClick={onClick} to="/tenders">
            Tenders
          </NavigationLink>
        </div>
        {flags.showTenderCertifications && (
          <div className="pl-2">
            <NavigationLink onClick={onClick} to="/tender-certifications">
              Tender Certifications
            </NavigationLink>
          </div>
        )}
      </Can>

      {flags.showClientManagement && (
        <Can I="update" a="Client">
          <NavigationSectionGroup>Client Management</NavigationSectionGroup>
          <div className="pl-2">
            <NavigationLink onClick={onClick} to="/assignments">
              Assignments
            </NavigationLink>
          </div>
        </Can>
      )}

      <Can I="update" a="Region">
        <NavigationSectionGroup>Regions</NavigationSectionGroup>
        <div className="pl-2">
          {flags.staffingRegions && (
            <NavigationLink onClick={onClick} to="/staffing-regions">
              Staffing Regions
            </NavigationLink>
          )}
          <NavigationLink onClick={onClick} to="/regions">
            Manage Regions
          </NavigationLink>
        </div>
      </Can>
    </nav>
  );
};

type Props = {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
};

const Navigation: React.FC<Props> = ({ sidebarOpen, setSidebarOpen }) => {
  const closeButtonRef = useRef(null);
  const { isLoading } = useContext(AdminProfileContext);

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 flex"
          open={sidebarOpen}
          onClose={setSidebarOpen}
          initialFocus={closeButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col border-r bg-white pb-4">
              <div className="box-border flex h-16 flex-shrink-0 items-center border-b">
                <button
                  ref={closeButtonRef}
                  className="h-full border-gray-200 px-4 text-gray-500 focus:outline-none"
                  onClick={() => {
                    setSidebarOpen(false);
                  }}
                >
                  <span className="sr-only">Close sidebar</span>
                  <HamburgerIcon />
                </button>
                <div className="text-preset-5 font-medium">Admin Panel</div>
              </div>

              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="space-y-1 px-2">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <NavigationItems onClick={() => setSidebarOpen(false)} />
                  )}
                </nav>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Navigation;
